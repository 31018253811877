/*********************************************************
 * Tag
 *********************************************************/

@mixin tag($color) {
	border-color: $color;
	color: $color;

	&:hover {
		background-color: rgba($color, 0.1);
	}

	&:focus {
		box-shadow: none;
	}

	&.tag-filled {
		background-color: $color;
		color: $white;
	}
}

.tag {
	align-items: center;
	display: inline-flex;
	justify-content: flex-start;
	margin-right: 1rem;
}

.tag-text,
.tag-remove {
	align-items: center;
	border-radius: $border-radius;
	display: flex;
	font-size: 1.2rem;
	font-weight: 600;
	height: auto;
	justify-content: center;
	line-height: 1.5;
	margin: 0;
	min-height: 100%;
	padding: 0.1em 0.5em;
}

.tag-sm {
	.tag-text,
	.tag-remove {
		font-size: 1rem;
	}
}

.tag-lg {
	.tag-text,
	.tag-remove {
		font-size: 1.4rem;
	}
}

.tag-blue {
	color: $white;
	background-color: $blue;
}

.tag-green {
	color: $white;
	background-color: $app-green;
}

.tag-yellow {
	color: $white;
	background-color: $app-yellow;
}

.tag-red {
	color: $white;
	background-color: $app-red;
}

.tag-gray {
	color: $black;
	background-color: $gray-lighter;
}

.tag-with-remove {
	background-color: $white;
}

.tag-with-remove .tag-text {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.tag-with-remove .tag-remove {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.tag-remove {
	background-color: $white;
	cursor: pointer;

	.svg-icon {
		font-size: calc(1rem / 10 * 3);
		margin-bottom: -2px;
	}
}

button.tag {
	display: inline-block;
	background-color: transparent;
	border-radius: 15px;
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 1;
	margin-top: 1.5rem;
	overflow: hidden;
	padding: 0.4em 1em;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 100%;
	border: 2px solid $black;
	color: $black;

	&:hover {
		cursor: pointer;
		text-decoration: none;
	}

	&:hover,
	&:focus {
		outline: none;
		text-decoration: none;
	}

	&:focus {
		box-shadow: none;
	}

	&.tag-orange {
		@include tag(#ec7c28);
	}

	&.tag-blue {
		@include tag($blue);
	}

	&.tag-yellow {
		@include tag($app-yellow);
	}

	&.tag-green {
		@include tag($app-green);
	}
}
