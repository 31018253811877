/*********************************************************
 * Colors
 *********************************************************/
$black: #000 !default;
$white: #fff !default;
$white-facelift: #f4f7fb !default;

$gray-darker: #596164 !default;
$gray-dark: #6a7175 !default;
$gray: #81898f !default;
$gray-light: #a5aeb5 !default;
$gray-lighter: #e1e1e1 !default;
$gray-lightest: #f5f5f5 !default;

$blue: #006ab3 !default;
$blue-light: #4a9bd2 !default;
$blue-light-facelift: #65a4cb !default;
$blue-lightest-facelift: #cadeed !default;
$blue-link: #026cb4 !default;

$orange: #ec7c28 !default;

/**
 * App colors: Used for components like forms, alerts etc.
**/
$app-blue: $blue-light !default;
$app-green: #3abc58 !default;
$app-orange: #f4993f !default;
$app-red: #e44141 !default;
$app-yellow: #f1cc5d !default;

$new-blue: #004d80 !default;
$new-bg-blue: #e7f0f7 !default;

/**
* Specific color
**/
$body-background: $gray-lightest !default;
$footer-background: #2e3033 !default;
$text-color: #222 !default;
$gray-text-color: #757575 !default;

$primary-colors: (
	"blue": $app-blue,
	"green": $app-green,
	"orange": $app-orange,
	"red": $app-red,
	"yellow": $app-yellow,
) !default;

$gray-colors: (
	"gray-darker": $gray-darker,
	"gray-dark": $gray-dark,
	"gray": $gray,
	"gray-light": $gray-light,
	"gray-lighter": $gray-lighter,
	"gray-lightest": $gray-lightest,
) !default;

/**
* New
**/
$new-primary-colors: (
	0: #fcfcfd,
	25: #e7f0f7,
	50: #cadeed,
	100: #9bc2de,
	200: #65a3cb,
	300: #4287b5,
	400: #004d80,
	500: #28577c,
	600: #254a67,
	700: #233f57,
	800: #17293a,
) !default;

$neutral-colors: (
	0: #fcfcfd,
	25: #f9fafb,
	50: #f6f6f6,
	100: #f1f1f1,
	200: #e7eef3,
	300: #a5aeb5,
	400: #7e8a91,
	500: #596164,
	600: #455055,
	700: #2e3033,
	800: #171b25,
) !default;

$success-colors: (
	0: #f2fbf5,
	25: #e0f8e7,
	50: #c3efd0,
	100: #94e1ac,
	200: #5eca80,
	300: #35a458,
	400: #29904a,
	500: #24713d,
	600: #215a34,
	700: #1d4a2d,
	800: #0b2815,
) !default;

$warning-colors: (
	0: #fffaeb,
	25: #ffefc6,
	50: #fedf89,
	100: #fec84b,
	200: #fdb122,
	300: #f88f08,
	400: #db6904,
	500: #b64707,
	600: #94370c,
	700: #792e0e,
	800: #461502,
) !default;

$danger-colors: (
	0: #fef4ee,
	25: #fce7d8,
	50: #f0aba6,
	100: #f2a67f,
	200: #ec784b,
	300: #e75428,
	400: #ca391c,
	500: #b32c1b,
	600: #8f251d,
	700: #73221b,
	800: #3e0e0c,
) !default;

$app-colors: (
	"primary": $new-primary-colors,
	"neutral": $neutral-colors,
	"success": $success-colors,
	"warning": $warning-colors,
	"danger": $danger-colors,
	"white": $white,
	"black": $black,
) !default;

/*********************************************************
 * Fonts
 *********************************************************/
$font: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
	"Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", "Microsoft YaHei", "微软雅黑",
	"STXihei", "华文细黑", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif !default;
$geoFont: "Geogrotesque", $font !default;
$jpFont: "Geogrotesque", "BlinkMacSystemFont", -apple-system, "Segoe UI", "Roboto", "Oxygen",
	"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica,Arial",
	"游ゴシック", "Yu Gothic", "Meiryo UI", "メイリオ", "Meiryo", "ＭＳ ゴシック", "MS Gothic",
	sans-serif !default;
$robotoFont: "Roboto", "Arial", BlinkMacSystemFont, -apple-system, "Segoe UI", "Oxygen", "Ubuntu",
	"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Microsoft YaHei",
	"微软雅黑", "STXihei", "华文细黑", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
	sans-serif !default;

// Monospace only used for code
$monoFont: "SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro",
	monospace !default;

$render-mode: optimizeSpeed !default;
$ligatures: none !default;

// Sizes
$html-font-size: 62.5% !default;
$font-size-base: 1.6rem !default;

$font-size-h1: 3.3rem !default;
$font-size-h2: 2.7rem !default;
$font-size-h3: 2.3rem !default;
$font-size-h4: 2rem !default;
$font-size-h5: 1.8rem !default;
$font-size-h6: 1.6rem !default;

$nav-height: 6.4rem !default;
$page-padding: 3rem !default;

$iconBase: 16;

$border-radius: 2px;

// Screen sizes
$tablet-width: 768px;
$desktop-width: 1024px;

/*********************************************************
 * Z-INDEX
 *********************************************************/
$z-index: 1000 !default;
$z-index-nav: 1020 !default;
$z-index-nav-content: 1010 !default;
$z-index-nav-mobile: 1030 !default;
$z-index-search: 1040 !default;
$z-index-modal: 1050 !default;
$z-index-select-dropdown: 1060 !default;

@import "settings";
