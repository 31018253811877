/*********************************************************
 * Message
 *
 * Text container for messages
 *********************************************************/

%message {
	display: flex;
	gap: 12px;
	border-radius: 8px;
	padding: 12px 16px;

	> p {
		flex: 1 1 auto;
		margin: 0;
	}
}

.message {
	@extend %message;
	background-color: $gray-lightest;
	color: $gray-darker;
}

.message.info {
	@extend %message;
	background-color: $new-bg-blue;
	color: $new-blue;
}


