/*********************************************************
 * Nav List
 *********************************************************/

.list {
	border-bottom: 1px solid darken($gray-lightest, 10%);
	border-top: 1px solid darken($gray-lightest, 10%);
	list-style-type: none;
	margin: 1.6rem 0 0 0;
}

.list-item {
	align-items: center;
	background-color: $white;
	display: flex;
	padding: 1rem 1.6rem;

	&:only-child {
		border: 0;
	}
}

.list-item + .list-item {
	border-top: 1px solid darken($gray-lightest, 10%);
}

.list-title {
	flex: 1 1 auto;
	font-size: 1.4rem;
	font-weight: 500;
	margin-right: 1rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.list-tags {
	flex: 0 1 auto;
	display: flex;
	margin-top: 0;
}
