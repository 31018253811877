/*********************************************************
 * Min width media queries XS -> XL
 *********************************************************/

@mixin media-min-xs {
	@media screen and (min-width: map-get($breakpoints, "sm")) {
		@content;
	}
}
@mixin media-min-sm {
	@media screen and (min-width: map-get($breakpoints, "sm")) {
		@content;
	}
}
@mixin media-min-md {
	@media screen and (min-width: map-get($breakpoints, "md")) {
		@content;
	}
}
@mixin media-min-lg {
	@media screen and (min-width: map-get($breakpoints, "lg")) {
		@content;
	}
}
@mixin media-min-xl {
	@media screen and (min-width: map-get($breakpoints, "xl")) {
		@content;
	}
}

/*********************************************************
 * Max width media queries XS-XL
 *********************************************************/

@mixin media-max-sm {
	@media screen and (max-width: map-get($breakpoints, "sm") - 0.1em) {
		@content;
	}
}
@mixin media-max-md {
	@media screen and (max-width: map-get($breakpoints, "md") - 0.1em) {
		@content;
	}
}
@mixin media-max-lg {
	@media screen and (max-width: map-get($breakpoints, "lg") - 0.1em) {
		@content;
	}
}
@mixin media-max-xl {
	@media screen and (max-width: map-get($breakpoints, "xl") - 0.1em) {
		@content;
	}
}

/*********************************************************
 * Exact width media queries XS -> XL
 *********************************************************/

@mixin media-xs-only {
	@media screen and (max-width: map-get($breakpoints, "sm") - 0.1em) {
		@content;
	}
}
@mixin media-sm-only {
	@media screen and (min-width: map-get($breakpoints, "sm")) and (max-width: map-get($breakpoints, "md") - 0.1em) {
		@content;
	}
}
@mixin media-md-only {
	@media screen and (min-width: map-get($breakpoints, "md")) and (max-width: map-get($breakpoints, "lg") - 0.1em) {
		@content;
	}
}
@mixin media-lg-only {
	@media screen and (min-width: map-get($breakpoints, "lg")) and (max-width: map-get($breakpoints, "xl") - 0.1em) {
		@content;
	}
}
@mixin media-xl-only {
	@media screen and (min-width: map-get($breakpoints, "xl")) {
		@content;
	}
}

/*********************************************************
 * Set column width
 *********************************************************/

@mixin set-col-width($percentage) {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: $percentage;
	max-width: $percentage;
}
